import Vue from 'vue'
import App from './App.vue'

import VueI18n from "vue-i18n";
import zh_cn from "@/i18n/json/zh_cn.json";
import en_us from "@/i18n/json/en_us.json";
import th from "@/i18n/json/th.json";
Vue.use(VueI18n);
const localLang = localStorage.getItem("lang") || "zh_cn";

const i18n = new VueI18n({
  locale: localLang || "zh_cn", // 语言标识
  fallbackLocale: localLang || "zh_cn", //默认中文语言
  messages: { zh_cn, en_us, th },
});

import router from './router'

import Vant from 'vant';
import 'vant/lib/index.css';

Vue.use(Vant);
Vue.config.productionTip = false

window.app = new Vue({
    el: '#app',
    router,
    i18n,
    render: h => h(App)
})