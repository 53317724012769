import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

export const constantRouterMap = [

  {
    path: '/init_v2',
    name: 'init',
    component: () => import('../views/init.vue'),
    meta: {
      title: '工资条',
    }
  },


  {
    path: '/payslip_login_v2',
    name: 'payslip',
    component: () => import('../views/payslip_login.vue'),
    meta: {
      title: '工资条',
    }
  },

  {
    path: '/err',
    name: 'err',
    component: () => import('../views/err.vue'),
    meta: {
      title: 'err',
    }
  },

  {
    path: '/*',
    name: 'error',
    component: () => import('../views/error.vue'),
    meta: {
      title: 'error',
    }
  },
]

const createRouter = () => new Router({
  mode: 'history', // require service support
  scrollBehavior: () => ({ y: 0 }),
  routes: constantRouterMap
})

const router = createRouter()
router.beforeEach((to, from, next) => {
  if(to.meta.title){
    document.title = to.meta.title
  }
  next();
})
export default router


